import { Environment } from '@neuralegion/api';

export const environment: Omit<Environment, 'production'> = {
  version: 'v4.91.0-next.11272097230',
  commit: '31ca4a',

  archiveMaxFileSize: 524288000,
  reCaptchaSiteKey: '6LdnAakUAAAAACiqoGaDrqZXEYbcTCOPtc37ABJp',
  segmentWriteKey: '',
  sentryDsn: 'https://303c0a925ad74928b19e0832384bcbab@sentry.io/1809867',

  invalidDataIdReporterEnabled: false,
  pagePatternLogoutIndicatorEnabled: true,
  projectForAuthObjectRequired: false,
  projectLevelEntrypointsEnabled: true,
  projectExternalScanConfigSourcesEnabled: false,
  snykAppsEnabled: false,
  rdpBasedAuthRecorderEnabled: false,
  rtcBasedAuthRecorderEnabled: false
};
